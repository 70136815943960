import type { CookieOptions } from '#app'
import { useCookie, useState, watch } from '#imports'

// Make cookie use state across app
export default function useStatefulCookie<T = string | null | undefined>(
  name: string,
  options?: CookieOptions<T> & {
    readonly?: false
  },
) {
  const cookie = useCookie(name, options)
  const state = useState(name, () => cookie.value)

  watch(
    state,
    () => {
      cookie.value = state.value
    },
    { deep: true },
  )

  return state
}
