import { useQuery } from '@tanstack/vue-query'
import { queries } from '~/keys/index'
import type { SuccessResponse } from '~/types/response'
import type { User } from '~/types/auth'
import { roleKey } from '~/constants/auth'
import type { ROLES_CODES } from '~/constants/roles'
import useAuthStore from '~/store/auth'

const useCurrentUser = (enabled?: boolean) => {
  const { fetch } = useAPIFetch()
  const roleCookie = useStatefulCookie<ROLES_CODES>(roleKey)
  const { setRole } = useAuthStore()

  // TODO: remove meta section from users/me
  return useQuery({
    ...queries.users.me,
    queryFn: () =>
      fetch<SuccessResponse<User>>({
        method: 'GET',
        url: 'users/me',
      }),
    enabled,
    retry: false,
    select: data => {
      const { role } = data.data

      // Always update user role cookie
      if (role) {
        roleCookie.value = role.code
        setRole(role.code)
      }

      return data.data
    },
  })
}

export default useCurrentUser
